var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('v-card',{attrs:{"outlined":"","tile":"","elevation":"1"}},[_c('v-card-title',[_vm._v(" Stock Productos "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"blue"},on:{"click":_vm.fetchData}},on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Actualizar Tabla")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","icon":"","color":"green"},on:{"click":_vm.exportExcel}},on),[_c('v-icon',[_vm._v("fas fa-file-excel")])],1)]}}])},[_c('span',[_vm._v("Exportar Excel")])])],1),_c('v-card-text',[_c('v-card',{attrs:{"outlined":"","tile":"","elevation":"1"}},[_c('v-card-text',{staticStyle:{"padding-bottom":"0px"}},[_c('div',{staticClass:"d-flex justify-space-between mb-4"},[_c('v-text-field',{staticClass:"mr-2",attrs:{"clearable":"","label":"Buscar","hide-details":"","dense":"","solo":""},on:{"change":_vm.fetchData},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"btn-starkoms-primary",staticStyle:{"min-width":"138px"},attrs:{"dark":""},on:{"click":_vm.fetchData}},[_vm._v("Buscar")])],1)])],1)],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1 datatable",attrs:{"headers":_vm.headers,"items":_vm.listado,"items-per-page":_vm.selectItemsPerPage,"disable-pagination":true,"hide-default-footer":true,"loading":_vm.dataTableLoading,"sort-by":'id',"sort-desc":true,"disable-sort":true,"no-results-text":"No hay resultados disponibles","no-data-text":"No hay datos disponibles","loading-text":"Cargando datos..."},scopedSlots:_vm._u([{key:"item.is_bundle",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"text-center"},[(item.is_bundle)?_c('button',{class:'btn btn-md btn-warning',domProps:{"textContent":_vm._s('Bundle')}}):_c('button',{class:'btn btn-md btn-danger',domProps:{"textContent":_vm._s('Producto')}})])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('router-link',{attrs:{"to":("/inventario/stock/productos/" + (encodeURIComponent(item.sku)))}},[_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"#93a2dd","small":""}},on),[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver Producto SKU: "+_vm._s(item.sku))])])]}},{key:"item.summary.reserved_quantity",fn:function(ref){
var item = ref.item;
return [(item.summary.reserved_quantity == 0)?_c('td',{staticClass:"text-left"},[_vm._v(" "+_vm._s(item.summary.reserved_quantity)+" ")]):_vm._e(),(item.summary.reserved_quantity > 0)?_c('td',{staticClass:"text-left"},[_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":'#'},on:{"click":function($event){return _vm.listadoOrdenesReserva(item)}}},[_vm._v(_vm._s(item.summary.reserved_quantity))])]):_vm._e()]}}])}),_c('div',{staticClass:"d-flex justify-space-between mt-4"},[_c('v-select',{staticStyle:{"max-width":"120px"},attrs:{"label":"Registros por página","items":[10,20,50,100, 500]},on:{"input":_vm.fetchData},model:{value:(_vm.selectItemsPerPage),callback:function ($$v) {_vm.selectItemsPerPage=$$v},expression:"selectItemsPerPage"}}),_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":100,"color":"#e55d43"},on:{"input":_vm.fetchData},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)])]),_c('dialog-listado-ordenes-reserva')],1)}
var staticRenderFns = []

export { render, staticRenderFns }