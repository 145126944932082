<template>
    <v-app>
        <v-dialog v-model="showDialog" max-width="1000px">
            <v-card v-if="showDialog">
                <v-card-title> <span>SKU {{sku}} - Origen de reservas</span> </v-card-title>
                <v-card-text>
                    <template>
                        <v-simple-table>
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th class="text-left"> # Orden </th>
                                        <th class="text-left"> Referencia </th>
                                        <th class="text-left"> Cant. reservada </th>
                                        <th class="text-left"> Fecha </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in reservationsList" :key="item.id" >
                                        <td>
                                            <a class="text-decoration-none" :href="'/#/ordenes/'+item.order_id" target="_blank" >{{item.order_id}}</a>
                                        </td>
                                        <td>{{ item.order_reference }}</td>
                                        <td>{{ item.quantity }}</td>
                                        <td>{{$moment(item.created_at).format('LLLL')}}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </template>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" text @click="showDialog = false" > Cerrar </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>
export default {
    data(){
        return {
            showDialog: false,
            sku: undefined,
            store_id: undefined,
            current_state: undefined,
            current_substate: undefined,
            reservationsList: [],
            orderSubStatesList: [],
        }
    },
    created(){        
        this.$parent.$on('openDialogListadoOrdenesReserva', (datos) => {
            this.sku = datos.sku;
            this.fetchData(datos);
        });
    },
    methods: {
        fetchData(datos){
            var vm = this;
            this.axios({
                url: 'inventory/stock/reservation/by_sku',
                method: 'POST',
                data: datos
            }).then( response => {
                vm.reservationsList = response.data;
                vm.showDialog = true; 
            }).catch( (error) => {
                console.log(error);
            });
        }
    }
}
</script>